<!-- 超管数据单据销售单 -->
<template>
  <div class="orderTest-container">
    <el-dialog :visible.sync="admindata" :title="name" center width="70%">
      <div>
        <el-form ref="form" :model="form" inline>
          <el-form-item prop="time">
            <el-date-picker
              v-model="form.time"
              style="width: 350px"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="status">
            <el-select
              v-model="form.status"
              placeholder="状态"
              style="width: 160px"
            >
              <el-option
                v-for="list in statusd"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="bill_code">
            <el-input v-model="form.bill_code" placeholder="单据编号" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerInquire">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          stripe
          :data="tableData"
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            width="80"
            align="center"
            type="selection"
          ></el-table-column>
          <el-table-column
            v-for="(list, index) in columne"
            :key="index"
            :label="list.label"
            :align="list.align"
            :prop="list.prop"
            width=""
          ></el-table-column>
        </el-table>
        <el-row type="flex" class="row-bg" justify="end">
          <el-pagination
            background
            :current-page="form.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlerremove">删除</el-button>
        <el-button type="warning" @click="handlerrestore">还原</el-button>
        <el-button @click="clear">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      return {
        name: '',
        admindata: false,
        total: 0,
        tableData: [],
        form: {
          id: '', //企业ID
          bill_id: '', //单据类型ID
          time: [],
          start_time: '', //开始时间 如2021-6-21
          end_time: '', //结束时间 如2021-6-27
          status: '', //状态 0全部 1正常 2已作废 3已删除
          bill_code: '', //单据编号
          pageNo: 1,
          pageSize: 10,
        },
        statusd: [
          {
            id: 0,
            name: '全部',
          },
          {
            id: 1,
            name: '正常',
          },
          {
            id: 2,
            name: '已作废',
          },
          {
            id: 3,
            name: '已删除',
          },
        ],
        columne: [
          {
            label: '单据号',
            align: 'center',
            prop: 'bill_code',
            width: '',
          },
          {
            label: '状态',
            align: 'center',
            prop: 'status',
            width: '',
          },
          {
            label: '时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
        ],
        url: {
          save: '/superAdmin/user-data/bill-detail',
          detail: '/superAdmin/user-data/bill-detail-do',
        },
        order_id: '',
      }
    },

    computed: {},
    watch: {
      'form.time'(val) {
        try {
          if (val.length > 0) {
            this.form.start_time = val[0]
            this.form.end_time = val[1]
          } else {
            this.form.start_time = ''
            this.form.end_time = ''
          }
        } catch (error) {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      handlerInquire() {
        this.handlerlist()
      },
      getRowKey(row) {
        return row.data_id
      },
      handlerremove() {
        postAction(this.url.detail, {
          id: this.form.id,
          data_ids: this.order_id,
          bill_id: this.form.bill_id,
          status: 1,
        })
          .then((res) => {
            console.log(res)
            this.$message({ type: 'success', message: res.msg })
            this.handlerlist()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerrestore() {
        postAction(this.url.detail, {
          id: this.form.id,
          data_ids: this.order_id,
          bill_id: this.form.bill_id,
          status: 2,
        })
          .then((res) => {
            console.log(res)
            this.$message({ type: 'success', message: res.msg })
          })
          .catch((err) => {
            console.log(err)
          })
      },
      clear() {
        this.admindata = false
        this.$refs['form'].resetFields()
      },
      handlerdata(row) {
        console.log(row)
        this.name = row.name
        this.form.id = row.id
        this.form.bill_id = row.bill_id
        this.handlerlist()
      },
      handleSelectionChange(e) {
        console.log(e, '选的')
        this.order_id = e
          .map((list) => {
            return list.data_id
          })
          .join(',')
      },
      handlerlist() {
        postAction(this.url.save, this.form)
          .then((res) => {
            console.log('res', res)
            this.tableData = res.data.rows
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log('err', err)
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
