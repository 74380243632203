var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.admindata,
            title: _vm.name,
            center: "",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.admindata = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form, inline: "" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "time" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.form.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "time", $$v)
                          },
                          expression: "form.time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { placeholder: "状态" },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        _vm._l(_vm.statusd, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "bill_code" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "单据编号" },
                        model: {
                          value: _vm.form.bill_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bill_code", $$v)
                          },
                          expression: "form.bill_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerInquire },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    data: _vm.tableData,
                    "row-key": _vm.getRowKey,
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "80", align: "center", type: "selection" },
                  }),
                  _vm._l(_vm.columne, function (list, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: list.label,
                        align: list.align,
                        prop: list.prop,
                        width: "",
                      },
                    })
                  }),
                ],
                2
              ),
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "end" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.form.pageNo,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.form.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerremove },
                },
                [_vm._v("删除")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning" },
                  on: { click: _vm.handlerrestore },
                },
                [_vm._v("还原")]
              ),
              _c("el-button", { on: { click: _vm.clear } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }